import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FirstStepForm } from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation';
import AuthAction from '../../Redux/action/AuthAction';
import { NavLink, useSearchParams  } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { FaRegEye, FaRegEyeSlash  } from "react-icons/fa";

const SignupForm = () => {
    const dispatch = useDispatch()
    const { varifyEmail, varifyOtppassword } = AuthAction();
    const [passwordVisible, setPasswordVisible] = useState(false);

    const { firstStepForm, formData, verificationStatus, checkStatus, otp_msz, otp_status, OtpStatus, source, loaderstatus } = useSelector(state => state.authdetail);

    const { values, errors, handleChange, setErrors, handleSubmit } = useFormvalidation(
        handleNext,
        FirstStepForm,
        firstStepForm
    );

    useEffect(() => {
        if (verificationStatus != undefined) {
          if (Object.keys(verificationStatus).length > 0) {
            setErrors({
              email: verificationStatus.message
            })
          } else {
    
          }
    
        }

        if (OtpStatus != undefined) {
          if (Object.keys(OtpStatus).length > 0) {
            setErrors({
              otp: OtpStatus.message
            })
          } else {
    
          }
    
        }
    
    }, [verificationStatus, OtpStatus])

    const [searchParams] = useSearchParams();

    var param = searchParams.get('q');

    function handleNext() {
   
        if(checkStatus == 0){
          dispatch({
              type: 'setfirstStepForm',
              payload: values
          });
    
          var input_data = {
              email: values.email,
              password : values.password,
              platform: 1
          }
    
          dispatch(varifyEmail(input_data))
        }else if(checkStatus == 1){
          var input_data1 = {
            email: formData.email,
            otp: parseInt(values.otp),
            id:formData.id,
          }
          dispatch(varifyOtppassword(input_data1, param))
        }
    
    }

    const togglePassword = () => {
      setPasswordVisible(prevState => !prevState);
    };

    useEffect(() => {

    }, [checkStatus])
    

  return (
    <div className="main-form">
      
      {checkStatus == 0 && 
          <>
            <div className="mb-4">
                <input type="email" size="40" aria-required="true" name="email" placeholder="Email" onChange={handleChange} />
                {errors.hasOwnProperty('email') && <span className='error'>{errors.email}</span>}
            </div>
            <div className="mb-4">
              <div className='password-div'>
                <input type={passwordVisible ? 'text' : 'password'} size="40" aria-required="true" name="password" placeholder="Password" onChange={handleChange} />
                {passwordVisible ? 
                    <FaRegEye onClick={togglePassword} />
                     : <FaRegEyeSlash onClick={togglePassword} />
                    }
              </div>  
                {errors.hasOwnProperty('password') && <span className='error'>{errors.password}</span>}
            </div>
            <div className="mb-0"><button type="submit" className="octf-btn" onClick={handleSubmit}>Sign Up</button>
            </div>
            {
            loaderstatus &&
            <div style={{ position: 'absolute', left: '79%', top: '67%', transform: 'translate(-50%, -50%)' }}>
              <Spinner animation="border" role="status" className='custom-spinners' size="sm" />
            </div>
            }
            <div className='space-20'></div>
           
            <div className='text-center'>
              <span className='account-text'>Already Have account?</span> <span><NavLink className="text-decoration-none redirect-text" to={`/${param}`} >Sign In</NavLink></span>
            </div>
       
          </>
        }
        {
          checkStatus == 1 && 
          <>
            <div className="mb-4">
                <input type="email" size="40" aria-required="true" name="otp" placeholder="Enter OTP *" onChange={handleChange} />
                {errors.hasOwnProperty('otp') && <span className='error'>{errors.otp}</span>}
            </div>
            
            <div className="mb-0"><button type="submit" className="octf-btn" onClick={handleSubmit}>Verify</button>
            {loaderstatus && 
            <div style={{ position: 'absolute', left: '79%', top: '76%', transform: 'translate(-50%, -50%)' }}>
              <Spinner animation="border" role="status" className='custom-spinners' size="sm" />
            </div>
            }
            </div>
          </>
        }

      
    </div>
  )
}

export default SignupForm
