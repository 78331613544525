import React, {useEffect, useRef, useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";
import { useParams, useSearchParams, NavLink } from 'react-router-dom';
import shopersbayFavicon from '../images/shopersbay_fav.png'
import auditFavicon from '../images/auditsbay-favicon.png'
import iraxFavicon from '../images/irax-favicon.png'
import bg from '../images/desktop_background_img.webp'
import {useSelector, useDispatch} from 'react-redux'
import AuthAction from '../Redux/action/AuthAction';

const SelectPlan = () => {
    const formRef = useRef(null);
    const yearlyFormRef = useRef(null);
    const dispatch = useDispatch()
    const {addToCart, confirmPayment} = AuthAction()
    const [favicon, setFavicon] = useState("")
    const [metatitle, setMetaTitle] = useState("")
    const [isYearlyBilling, setIsYearlyBilling] = useState(true);
    const {subscriptionData, subfilterdata, token} = useSelector(state => state.authdetail);
   
    const montlydata = subscriptionData != null && subscriptionData.filter(ele => ele.type === "Month");
    const yeardata = subscriptionData != null && subscriptionData.filter(ele => ele.type === "Year");


    const {slug} = useParams()

    const [searchParams] = useSearchParams();

    var param = searchParams.get('q');
    var paymentStatus = searchParams.get('payment');

    useEffect(()=>{
        if(param === "shopersbay"){
        setFavicon(shopersbayFavicon)
        setMetaTitle("Shopersbay - All-in-One")
        }else if(param === "auditsbay"){
        setFavicon(auditFavicon)
        setMetaTitle("Auditsbay")
        }else if(param === "irax"){
        setFavicon(iraxFavicon)
        setMetaTitle("Your Technology Partner for Software Innovation and Market leading Solutions")
        }
    },[param])

    useEffect(() => {
        dispatch({
            type: 'setSubFilterData',
            payload: isYearlyBilling ? yeardata : montlydata ,
        });

    }, [subscriptionData]);

    const handleChange  = () => {
        setIsYearlyBilling(!isYearlyBilling);
        dispatch({
            type: 'setSubFilterData',
            payload: isYearlyBilling ? montlydata : yeardata,
        });
    }

    const handleSubscription = (id) => {
        localStorage.setItem('abcd', id)
        var data ={
            "product_id": id,
            "quantity" : 1,
            "type": "subscription"
        }
        dispatch(addToCart(data))
    }


    useEffect(()=>{
        var tokenParam = searchParams.get('token');
        if(paymentStatus === 'success'){
            const data ={
                "token": tokenParam,
            }
            dispatch(confirmPayment(data))
        }
    },[])


    // Function to dynamically load the Razorpay script
    // const loadRazorpayScript = (formRef, scriptId, subscriptionButtonId) => {
    //     // Check if the script is already present
    //     if (formRef.current && !formRef.current.querySelector(`#${scriptId}`)) {
    //     const script = document.createElement('script');
    //     script.src = 'https://cdn.razorpay.com/static/widget/subscription-button.js';
    //     script.setAttribute('data-subscription_button_id', subscriptionButtonId);
    //     script.setAttribute('data-button_theme', 'brand-color');
    //     script.async = true;
    //     script.id = scriptId;
    //     script.onload = () => {
    //         // console.log('Razorpay script loaded successfully.');
    //     };

    //     formRef.current.appendChild(script);
    //     } else {
    //     // console.log('Razorpay script already loaded or formRef not set.');
    //     }
    // };

    // useEffect(() => {
    //     if (isYearlyBilling) {
            // loadRazorpayScript(yearlyFormRef, 'yearlyScript', 'pl_OhC5mWmYQUKWW4');
            // loadRazorpayScript(yearlyFormRef, 'yearlyScript', 'pl_OcmNXJbferWabK');
            // const existingScript = document.getElementById('monthlyScript');
            
            // if (existingScript) {
            //     existingScript.parentElement.innerHTML="";
            // }
        // } else {
        //   loadRazorpayScript(formRef, 'monthlyScript', 'pl_OhC6pTxPdkKNSr');
        //   loadRazorpayScript(formRef, 'monthlyScript', 'pl_OcmNa6zXYPKL05');

        //   const existingScript = document.getElementById('yearlyScript');
        //   if (existingScript) {
        //       existingScript.parentElement.innerHTML="";
        //   }
    //     }
    // }, [isYearlyBilling]);



    



    return (
        <>
            <Helmet>
                <link rel="icon" href={favicon} />
                <title>{metatitle}</title>
            </Helmet>
            <div class="pricing-table-section select-plan" style={{ backgroundImage: `url('${bg}')`}}>
                <Container fluid>
                    <div className="space-90"></div>
                    <Row>
                        <Col lg={8} className='offset-lg-2 text-center'>
                            <div className='ot-heading'>
                                <span className="is_highlight">flexible pricing plans</span>
                                <h3 className="main-head text-white">Choose The Best Plan</h3>
                            </div>
                            <div className="space-20"></div>
                        
                            <p className="text-white px-xl-5 mx-xl-5">Choose the plan that caters to your specific requirements, ensuring a seamless experience that propels you towards success.</p>
                            <div className="space-30"></div>
                            <div className="ot-switcher">
                                <span className="l-switch  text-white">Billed Monthly</span>
                                <label className={`switch form-check-label`} htmlFor='toggleMonth'>
                                    <input type="checkbox" id="toggleMonth" onChange={handleChange} style={{opacity:0, border:  '1px solid red'}} />
                                    <span className={`slider round ${isYearlyBilling ? "activeclass" : "inactiveclass"}`}></span>
                                </label>
                                <span className="r-switch  text-white">Billed Yearly</span>
                            </div>
                            <div className="space-60"></div>
                        </Col>
                    </Row>
                    <Row className='pricing-monthly'>
                        {
                            subfilterdata != null && subfilterdata.length > 0 &&
                            subfilterdata.map((ele, i) => {
                                return(
                                    <Col xl={3} md={6} className='mb-5 mb-xl-0' key={i}>
                                    <div className="ot-pricing-table ">
                                        <div className="layer-behind"></div>
                                        <div className="inner-table">
                                            <div className="title-table"><span>{ele.name}</span></div>
                                            {
                                                ele.price !== '0' ?
                                            <> <div className="amt"><sup>{localStorage.getItem("currency") != undefined ? localStorage.getItem("currency") : "$"}</sup> {ele.price}</div><h6>{ele.type}</h6></> : ""
                                            }
                                        
                                            <div className="" dangerouslySetInnerHTML={{ __html: ele.description }}/>
                                        {
                                            ele.price !== '0' ?
                                            <button type="button" onClick={()=>handleSubscription(ele.subscriptionid)}
                                            className="octf-btn octf-btn-border" ><NavLink className={"text-decoration-none"} /* to="https://login.shopersbay.com/signup?q=shopersbay" target="_blank" */ >Buy Now</NavLink></button>
                                            :
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                            
                                            className="octf-btn octf-btn-border" >Get Quote</button>

                                        }
                                        
                                        </div>
                                    </div>
                                </Col>
                                )
                            
                            })
                        }
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SelectPlan
