const initialState = {
  checkStatus: 0,
  forgotStatus: 0,
  firstStepForm: {
    email: "",
    password: "",
  },
  forgotVarification: {
    forgotemail: "",
  },
  updateForm: {
    newPassword: "",
    confirmPassword: "",
  },
  formData:{},
  verificationStatus:{},
  setotp_msz:"",
  otp_status: false,
  otpStatus:{},
  favicon:"",
  logowhite:"",
  logo:"",
  color:"",
  source:"",
  token:"",
  loginError:"",
  loaderstatus: false,
  masterData:{},
  subscriptionData: null,
  subfilterdata: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'setcheckStatus':
      return {
        ...state,
        checkStatus: action.payload
      };
    case 'setforgotStatus':
      return {
        ...state,
        forgotStatus: action.payload
      };
    case 'setfirstStepForm':
      return {
        ...state,
        firstStepForm: action.payload
      };
    case 'setforgotVarification':
      return {
        ...state,
        forgotVarification: action.payload
      };
    case 'setformData':
      return {
        ...state,
        formData: action.payload
      };
    case 'setVerification_status':
      return {
        ...state,
        verificationStatus: action.payload
      };
    case 'setOtpStatus':
      return {
        ...state,
        OtpStatus: action.payload
      };
    case 'setotp_msz':
        return {
          ...state,
          otp_msz: action.payload
        };
    case 'setotp_status':
        return {
          ...state,
          otp_status: action.payload
        };
    case 'setfavicon':
        return {
          ...state,
          favicon: action.payload
        };
    case 'setlogowhite':
        return {
          ...state,
          logowhite: action.payload
        };
    case 'setlogo':
        return {
          ...state,
          logo: action.payload
        };
    case 'setSource':
        return {
          ...state,
          source: action.payload
        };
    case 'setToken':
        return {
          ...state,
          token: action.payload
        };
    case 'setloginError':
        return {
          ...state,
          loginError: action.payload
        };
    case 'setloaderstatus':
        return {
          ...state,
          loaderstatus: action.payload
        };
    case 'setupdateForm':
        return {
          ...state,
          updateForm: action.payload
        };
    case 'setmasterData':
        return {
          ...state,
          masterData: action.payload
        };
    case 'setsubscriptionData':
        return {
          ...state,
          subscriptionData: action.payload
        };
    case 'setSubFilterData':
        return {
          ...state,
          subfilterdata: action.payload
        };

  default:
      return state;
  }
};