import React, {useState, useEffect} from 'react'
import * as common from '../components/common'
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import shopersbayFavicon from '../images/shopersbay_fav.png'
import auditFavicon from '../images/auditsbay-favicon.png'
import iraxFavicon from '../images/irax-favicon.png'

const SignIn = () => {
  const [favicon, setFavicon] = useState("")
  const [metatitle, setMetaTitle] = useState("")
  const {slug} = useParams()

  useEffect(()=>{
    if(slug === "shopersbay"){
      setFavicon(shopersbayFavicon)
      setMetaTitle("Shopersbay - All-in-One")
    }else if(slug === "auditsbay"){
      setFavicon(auditFavicon)
      setMetaTitle("Auditsbay")
    }else if(slug === "irax"){
      setFavicon(iraxFavicon)
      setMetaTitle("Your Technology Partner for Software Innovation and Market leading Solutions")
    }
  },[slug])
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>{metatitle}</title>
        {/* <meta property="og:title" content={localStorage.getItem("title") + " - " + deataidata[0].title} /> */}
        <meta property="og:description" content={ metatitle} />
        <meta property="og:image" content="https://admin.shopersbay.com/assets/uploads/1005/ckeditor/28e4193482fa655385a5a9491be849bb.png" />
      </Helmet>
      <common.AuthComponent />
    </>
  )
}

export default SignIn
