import React, {useState, useEffect} from 'react'
import { NavLink, useParams, useLocation, useSearchParams } from 'react-router-dom';
import shopersbayFavicon from '../../images/shopersbay_fav.png'
import shopwhite from '../../images/shopersbay_logo_dark.png'
import auditFavicon from '../../images/auditsbay-favicon.png'
import auditLogo from '../../images/auditsbay-logo.png'
import auditwhite from '../../images/auditsbay-logo-white.png'
import iraxFavicon from '../../images/irax-favicon.png'
import iraxLogo from '../../images/irax-logo.webp'
import iraxwhite from '../../images/irax-logo-white.png'
import bg from '../../images/desktop_background_img.webp'
import SignupForm from './SignupForm'
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';
import {useDispatch} from 'react-redux'
import Typewriter from 'typewriter-effect';
import { useMediaQuery } from 'react-responsive';
// import MasterAction from '../../Redux/action/MasterAction';


const AuthComponent = () => {
  const location = useLocation();
  const {slug} = useParams()
  const dispatch = useDispatch()
  const [logo, setLogo] = useState("")
  const [logoWhite, setlogoWhite] = useState("")
  const [favicon, setFavicon] = useState("")
  const [title, setTitle] = useState("")
  const [metatitle, setMetaTitle] = useState("")
  const [url, setUrl] = useState("")
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [searchParams] = useSearchParams();
  var param = searchParams.get('q');
  // const {getSubscription} = MasterAction()

  useEffect(()=>{
    dispatch({
      type: 'setSource',
      payload: slug
    });

    if(slug === "shopersbay" || param === "shopersbay" || location.pathname === '/'){
     
      setlogoWhite(shopwhite)
      setFavicon(shopersbayFavicon)
      setTitle("Shopersbay")
      setMetaTitle("Shopersbay - All-in-One")
      localStorage.setItem('ssids', '21e7ac68af78a3be93bbf71958f216d9')
      // dispatch({
      //   type: 'setToken',
      //   payload: "21e7ac68af78a3be93bbf71958f216d9"
      // });
    }else if(slug === "irax" || param === "irax"){
      setLogo(iraxLogo);
      setlogoWhite(iraxwhite)
      setFavicon(iraxFavicon)
      setTitle("Irax")
      setMetaTitle("Your Technology Partner for Software Innovation and Market leading Solutions")
      localStorage.setItem('ssids', 'hk7ei9zazwf93uvhqcod65uyfpdu50fl')
      // dispatch({
      //   type: 'setToken',
      //   payload: "hk7ei9zazwf93uvhqcod65uyfpdu50fl"
      // });
    }else if(slug === "auditsbay" || param === "auditsbay"){
      setLogo(auditLogo);
      setlogoWhite(auditwhite)
      setFavicon(auditFavicon)
      setTitle("Auditsbay")
      setMetaTitle("Auditsbay")
      localStorage.setItem('ssids', 'hk7ei9zazwf93uvhqcod65uyfpdu50fl')
      // dispatch({
      //   type: 'setToken',
      //   payload: "549681be7a669a80f686e15f8d8b2d79"
      // });
    }
  },[slug])

  

  useEffect(()=>{
    if(param === 'shopersbay'){
      setUrl("https://shopersbay.com/")
    }
   
  },[param])

  return (
    <>
      
      <section className="finance-banner" /* style={{ backgroundImage: `url('${bg}')`}} */>

        <div className="container-fluid">
            <div className="row finance-banner-row" style={{ backgroundImage: `url('${bg}')`}}>
                <div className="col-xl-6 col-lg-7 mb-5 mb-lg-0">
                  {!isMobile &&
                  <div className='space-90'></div>
                  }
                
                  <div className='title-text'>
                    
                    <div>
                      <NavLink to="https://shopersbay.com/"><img src={logoWhite} alt="" style={{width: "50%"}} /></NavLink>
                    </div>
                    <div className='space-20'></div>
                    <div className='row'>
                      <h2>All-in-One Platform For<br/>
                        <span style={{color: "#ff9900"}}>
                          <Typewriter
                            options={{
                              strings: ['E-Commerce', 'Audits', 'Asset Management', 'HRIS'],
                              autoStart: true,
                              loop: true,
                            }}
                          />
                        </span>
                      </h2>
                    </div>
                      
                  </div>                     
                </div>
                <div className="offset-xl-1 col-lg-5">
                    
                    <div className="finance-quote-form">
                      <div className="space-20"></div>
                      {/* <div>
                        <img className='img-fluid' src={shopLogo} alt=""></img>
                      </div> */}
                      <div className="space-20"></div>
                      {location.pathname !== '/forgot-password' ?
                        <>
                          <span className="wlcome-text">Welcome to </span><span className="shopersbay-text">{title}</span>
                          <div className="space-20"></div>
                          <h3>Get Started with your email</h3>
                        </>
                        :
                        <>
                          <span className="wlcome-text"></span><span className="shopersbay-text">Forgot Password</span>
                          <div className="space-20"></div>
                          <h4>Enter the email address associated with your account to get OTP.</h4>
                        </>
                      }
                      <div className="space-20"></div>
                      <div  className="wpcf7-form main-contact-form">
                        {location.pathname === '/signup' && 
                          <SignupForm />
                        }
                        {(location.pathname === '/' || location.pathname === '/shopersbay' || location.pathname === '/auditsbay' || location.pathname === '/irax')&& 
                          <LoginForm />
                        }
                        {location.pathname === '/forgot-password' && 
                          <ForgotPassword />
                        }
                      </div>
                      <div className="space-20"></div>
                      {location.pathname === '/signup' &&
                      <p className="agree-text">By continuing you agree to our <NavLink to={`https://shopersbay.com/policies/privacy-policy`}>Privacy Policy</NavLink> and <NavLink to="https://shopersbay.com/policies/terms-of-use">Terms of Use</NavLink></p>
                      } 
                      </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default AuthComponent
