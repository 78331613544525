
import {useEffect} from 'react'
import AppRouter from "./routers/AppRouter";

function App() {
  
  return (
    <AppRouter />
  );
}

export default App;
