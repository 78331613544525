import React from 'react'
import { AuthComponent } from '../components/common'

const ForgotPassword = () => {
  return (
    <>
      <AuthComponent />
    </>
  )
}

export default ForgotPassword
