import React from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { NavLink, useParams, useLocation, useSearchParams } from 'react-router-dom';

const Apis = () => {
 

  const vendorToken = localStorage.getItem("ssids")

  axios.defaults.baseURL = "https://admin.shopersbay.com/";
  
  const cookies = new Cookies()

  axios.defaults.headers.common['authentication'] = vendorToken;

  const getSubscriptionData = async () => {
    // const response = await axios.get("http://localhost/admin.apanashopee.in/asapi/getSubscription");
    const response = await axios.get("asapi/getSubscription");
    return response;
  };

  const saveCustomer = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/register", input_data);
    const response = await axios.post("asapi/register", input_data);
    return response;
  }

  const LoginService = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/authlogin", input_data);
    const response = await axios.post("asapi/authlogin", input_data);
    return response;
  };

  const checkEmail = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/varifyMailandPhone", input_data);
    const response = await axios.post("asapi/varifyMailandPhone", input_data);
    return response;
  };

  const verifyotpService = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/varifyOtp_new", input_data);
    const response = await axios.post("asapi/varifyOtp_new", input_data);
    return response;
  };

  const forgotPasswordApi = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/authForgetPassword", input_data);
    const response = await axios.post("asapi/authForgetPassword", input_data);
    return response;
  };

  const updatePasswordApi = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/updateAuthPassword", input_data);
    const response = await axios.post("asapi/updateAuthPassword", input_data);
    return response;
  };

  const addCart = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/addToCart", input_data, {
    //   headers: { authorization: cookies.get("jwt") }
    // });
    const response = await axios.post("asapi/addToCart", input_data, {
      headers: { authorization: cookies.get("jwt") }
    });
    return response;
  }

  const orderCreation = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/placeOrder", input_data, {
    //   headers: { authorization: cookies.get("jwt") }
    // });
     const response = await axios.post("asapi/placeOrder", input_data, {
      headers: { authorization: cookies.get("jwt") }
    });
    return response;
  };

  const vendorCreation = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/createVendor", input_data);
    const response = await axios.post("asapi/createVendor", input_data);
    return response;
  };

  const getPaymentMethodService = async () => {
    const response = await axios.get("asapi/getPaymentMethod", {
      headers: { authorization: cookies.get("jwt") }
    });
    return response;
  };

  const getConfirmPayment = async (input_data) => {
    const response = await axios.post("asapi/confirmPayment", input_data, {
      headers: { authorization: cookies.get("jwt") }
    });
    return response
  }

  
  
  return {
    // getMasterData: getMasterData,
    saveCustomer: saveCustomer,
    LoginService: LoginService,
    checkEmail: checkEmail,
    verifyotpService: verifyotpService,
    forgotPasswordApi: forgotPasswordApi,
    updatePasswordApi: updatePasswordApi,
    orderCreation: orderCreation,
    vendorCreation: vendorCreation,
    getSubscriptionData: getSubscriptionData,
    addCart: addCart,
    getPaymentMethodService: getPaymentMethodService,
    getConfirmPayment: getConfirmPayment,
  }
}

export default Apis
