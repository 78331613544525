import React,{useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FirstStepForm } from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation';
import Spinner from 'react-bootstrap/Spinner';
import AuthAction from '../../Redux/action/AuthAction';
import OtpComponent from './OtpComponent';
import UpdatePasswordComponent from './UpdatePasswordComponent';
import {useSearchParams, NavLink} from 'react-router-dom'


const ForgotPassword = () => {
  const dispatch = useDispatch()
  const {forgotPassword, varifyOtppassword} = AuthAction()
  const { forgotVarification, loaderstatus, forgotStatus, formData, loginError} = useSelector(state => state.authdetail);

  const { values, errors, handleChange, setErrors, handleSubmit } = useFormvalidation(
    handleNext,
    FirstStepForm,
    forgotVarification
  );

  const [searchParams] = useSearchParams();

  var param = searchParams.get('q');

  function handleNext() {
    if(forgotStatus === 0){
      var input_data = {
        email: values.forgotemail
      }
      dispatch(forgotPassword(input_data))
    }else if(forgotStatus === 1){
      var input_data1 = {
        email: formData.email,
        otp: parseInt(values.otp),
        id:formData.id,
      }
      dispatch(varifyOtppassword(input_data1, param))
    }else if(forgotStatus === 2){

    }
  }
  useEffect(()=>{

  },[loginError])
  return (
    <div className="main-form">
      {forgotStatus === 0 && 
      <>
        <div className="mb-4">
            <input type="email" size="40" aria-required="true" name="forgotemail" placeholder="Email" onChange={handleChange} />
            {errors.hasOwnProperty('forgotemail') && <span className='error'>{errors.forgotemail}</span>}
        </div>
        {loginError !== "" && 
          <>
          <div className='error'>{loginError}</div>
          <div className='space-5'></div>
          </>
        }
        <div className="mb-0" /* style={{ position: "relative",pointerEvents:loaderstatus && 'none' }} */><button type="submit" className="octf-btn" onClick={handleSubmit}>Send OTP</button>
        </div>
        {
          loaderstatus &&
          <div style={{ position: 'absolute', left: '79%', top: '55%', transform: 'translate(-50%, -50%)' }}>
            <Spinner animation="border" role="status" className='custom-spinners' size="sm"  />
          </div>
        }
        <div className='space-20'></div>
        <div className='remenber'>
          <p>Wait, I remember my password... <NavLink to="/">Click here</NavLink></p>
        </div>
      </>
      }
      {forgotStatus === 1 && 
        <OtpComponent errors={errors} onchange={handleChange} handlesubmit={handleSubmit} values={values.otp} setErrors={setErrors}/>
      }

      {/* update password section */}

      {forgotStatus === 2 && 
        <UpdatePasswordComponent />
      }
    </div>
  )
}

export default ForgotPassword
